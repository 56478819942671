import $ from "jquery";
import 'slick-carousel';
import 'inputmask/dist/jquery.inputmask';

$('.phone-mask').inputmask({
  mask:"+7 (999) 999-99-99",
  showMaskOnFocus: true,
  showMaskOnHover: false,
});

$('#home-slider').slick({
  dots: true,
  slidesToShow:1,
  speed: 500,
  prevArrow: '<button id="home-slide__prev-arrow"></button>',
  nextArrow: '<button id="home-slide__next-arrow"></button>',

  responsive: [
    {
      breakpoint: 680,
      settings: {
        arrows:false,
      }
    }
  ]
});

$('#our-clients-slider').slick({
  dots: false,
  arrows:true,
  slidesToShow:9,
  speed: 300,
  infinite:true,
  prevArrow: '<button id="our-clients__prev-arrow"></button>',
  nextArrow: '<button id="our-clients__next-arrow"></button>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        arrows:false,
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows:true,
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});


$('#work-examples-slider').slick({
  arrows:true,
  slidesToShow:6,
  speed: 300,
  infinite:true,
  prevArrow: $('#work-examples-prev'),
  nextArrow: $('#work-examples-next'),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

$('#close-mobile-menu').click(function() {
  $('.mobile-menu').removeClass('open');
});

$('#open-mobile-menu').click(function() {
  $('.mobile-menu').addClass('open');
});

$('#contact-menu-btn-trigger').click(function() {
  $('.mobile-menu-contacts').addClass('open');
});

$('#close-mobile-menu-contact').click(function() {
  $('.mobile-menu-contacts').removeClass('open');
});

$('.close-modal-trigger, #bgx').click(function() {
  $('#bgx').fadeOut();
  $('.modal').fadeOut();
});

$('.modal-trigger').click(function(e) {
  e.preventDefault();
  
  let modal = $(this).attr('data-modal');

  $(modal).fadeIn();
  $('#bgx').fadeIn();
});

$('.modal form').submit(function(e) {
  e.preventDefault();
  $.ajax('/mailer.php', {
    method:'post',
    data:$(this).serialize(),
    success: function (data) {
      console.log(data);
      if (data.success) {
        $('.modal').fadeOut();
        $('#success-message').fadeIn();
      }
    }
  })
});


$('.tab-btn').click(function(e) {
  e.preventDefault();
  $(this)
    .parent()
    .find('.tab-btn')
    .removeClass('active');

    $(this).addClass('active');

  let content = $(this).attr('data-content');
  $(content).parent().find('.tab-content').removeClass('active');
  $(content).addClass('active');
});


$('.slider-result-txt').slick({
  slidesToShow:1,
  speed: 600,
  autoplay:true,
  arrows:false
});

$('.attach-input').change(function() {
  let files = $(this)[0].files;
  let list = $(this).parent().parent().find('.modal__attach-list');
  let innerList = ''; 

  for (var i = 0; i < files.length; i++) {
    innerList += '<li data-file-id="'+ i +'" title="'+ files[i].name +'"><span class="attach-file-icon"></span><span style="display:none" class ="remove-files-attach-files">&#215;</span>'+files[i].name+'<li>';
  }

  list.html(innerList);
  console.dir();
});


$(document).delegate('.remove-files-attach-files', 'click', function() {
  // let index = $(this).attr('data-file-id');
  // let input = $(this).parent().parent().find('.attach-input"');
});

$('.mobile-open-sub-menu').click(function(e) {
  e.preventDefault();
  let btn = $(this);
  btn.toggleClass('active');
  btn.parent().find('.top-nav-sub').slideToggle();
});
